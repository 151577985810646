<template>
  <div>
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <div class="rn-column-area rn-section-gap mt--150">
      <v-container>
            <center><h3 class="mb--100"><b>Το ιστορικό μου</b></h3></center>
           
            
            <div v-if="this.transactions.transactions.length == 0">
              Δεν υπάρχουν δεδομένα
            </div>
            <div v-else>
                <v-row>
                    <div class="overflow-auto" style="width: 100%;">
                      <b-table small :fields="fields" :items="transactions.transactions" :per-page="this.transactions.pagination.items_per_page"
                         class="elevation-1 "
                        stripped
                        hover responsive="sm">
                          <template #cell(logo)>
                            <img src="@/assets/images/logo/logo_elin_new.png" width="60px" style="min-width: 40px"/>
                          </template>
                          <template #cell(store.name)="data">
                            <p style="padding:10px">{{ data.item.transaction_type_system === 'exchange_points_with_coupon' && data.item.total_points < 0 ?
                                                         'Εξαργύρωση πόντων' : data.item.store.name }}</p>

                          </template>
                          <template #cell(total_price)="data">
                            <!-- <p style="padding:10px">{{ [2,3,7,8,9,10,11].includes(data.item.transaction_type_id) ? data.item.total_price + " €" : '-' }}</p> -->
                            <p v-if="['order_via_mobile_app','order_pos','order_via_android_pos','order_via_store_manager'].includes(data.item.transaction_type_system)" 
                            style="padding:10px">{{  data.item.total_price + " " + data.item.currency }}</p>
                             <p v-else-if="['refund'].includes(data.item.transaction_type_system)" style="padding:10px">{{ '-' + data.item.total_price  }}</p>
                             <p v-else style="padding:10px">{{ '-' }}</p>

                          </template>
                          <template #cell(total_points)="data">
                            <p style="padding:10px">{{  ['refund'].includes(data.item.transaction_type_system) ? '-' : data.item.total_points }}</p>
                          </template>
                          <template #cell(created_at)="data">
                            <p style="padding:10px">{{ moment(data.item.created_at) }}</p>
                          </template>

                        </b-table>
                     <b-pagination
                      class="mt--100"
                      v-if="this.transactions.pagination.total_pages > 1"
                      v-model="currentPage"
                      v-on:click.native="scrollToTop"
                      :per-page="this.transactions.pagination.items_per_page"
                      :total-rows="this.transactions.pagination.total"
                      aria-controls="transactionsList"
                      align="center"
                    ></b-pagination> 
                    </div>
                </v-row>
              </div>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import moment from 'moment';

  export default {
    components: {
      Header,
      Footer,
      moment
    },
    data() {
      return {
         fields: [
          // A column that needs custom formatting
          { key: 'logo', label: '', tdClass: 'nameOfTheClass' }, 
          { key: 'store.name', label: 'Στοιχεία' , tdClass: 'nameOfTheClass' }, 
          { key: 'total_price', label: 'Ποσό', tdClass: 'nameOfTheClass' }, 
          { key: 'total_points', label: 'Πόντοι', tdClass: 'nameOfTheClass' }, 
          { key: 'created_at', label: 'Ημερομηνία', tdClass: 'nameOfTheClass' }, 
        ],
        perPage: 10,
        currentPage: 1,
        loading: true,
      }
    },
    computed: {
       ...mapGetters (["transactions"]),
       ...mapGetters (["user"]),
      rows() {
        return this.transactions.length
      }

    },
     watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        this.currentPage = newQuestion
        await this.getTransactions(newQuestion);
      }
    },
    async  created () {
      //  if(!this.user){ this.$router.push('/home') }
      
      if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
       if(!this.transactions){
        this.$loading(true);
        await this.getTransactions(1);
        this.$loading(false);
      }else{
        await this.getTransactions(1);
      }
    },
    methods: {
      ...mapActions(["getTransactions"]),
      ...mapActions(["getUser"]),
      moment: function (created_at) {
        return moment(String(created_at)).format('DD-MM-YYYY, hh:mm');
      },
      scrollToTop() {
        this.$vuetify.goTo(0);
      }
    },
  };
</script>
